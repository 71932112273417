.HeaderSection {}

.a {
    fill: none;
}
.b {
    clip-path: url(#a);
}
.d {
    opacity: 0.5;
    isolation: isolate;
}